import React, { useState } from 'react';
import logo from './images/red-chip-clear.png';
import './App.css';
import Calculator from './Calculator';
import 'bootstrap/dist/css/bootstrap.min.css';
import Tip from './Tip';

let deferredPrompt: any;

function App() {
  // Initialize deferredPrompt for use later to show browser install prompt.
  const [showInstallPWA, setShowInsatllPWA] = useState(true);

  window.addEventListener('beforeinstallprompt', (e) => {
    // Prevent the mini-infobar from appearing on mobile
    e.preventDefault();
    // Stash the event so it can be triggered later.
    deferredPrompt = e;
    // Update UI notify the user they can install the PWA
    setShowInsatllPWA(true);
    // Optionally, send analytics event that PWA install promo was shown.
    console.log('"beforeinstallprompt" event was fired.');
  });

  window.addEventListener('appinstalled', () => {
    console.log('app is installed!');
    // Hide the app-provided install promotion
    setShowInsatllPWA(false);
    // Clear the deferredPrompt so it can be garbage collected
    deferredPrompt = null;
    // Optionally, send analytics event to indicate successful install
    console.log('PWA was installed');
  });

  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <div
          style={{
            paddingTop: '50px',
          }}
        >
          <h2>Poker Payout Calculator</h2>
          <span
            style={{
              fontSize: '.8em',
            }}
          >
            We created the Poker Game Payout Calculator because we needed a
            simple widget to calculate the payments that needed to occur at the
            end of our home poker games. Simply enter the player’s name, total
            buy-in amount, and the player’s closing balance and we’ll calculate
            the simplest way to make everybody whole.
          </span>
        </div>
        <div
          className="row button-box"
          style={{
            marginTop: '10px',
            marginBottom: '10px',
          }}
        >
          <div className="" style={{ marginRight: '10px' }}>
            <Tip />
          </div>
          {showInstallPWA && deferredPrompt && (
            <div>
              <button
                className="btn btn-primary btn-sm"
                onClick={async () => {
                  // Hide the app provided install promotion
                  setShowInsatllPWA(false);
                  // Show the install prompt
                  deferredPrompt.prompt();
                  // Wait for the user to respond to the prompt
                  const { outcome } = await deferredPrompt.userChoice;
                  // Optionally, send analytics event with outcome of user choice
                  console.log(
                    `User response to the install prompt: ${outcome}`
                  );
                  // We've used the prompt, and can't use it again, throw it away
                  deferredPrompt = null;
                }}
              >
                Install App
              </button>
            </div>
          )}
        </div>
      </header>

      <div className="container">
        <section className="row">
          <div className="col-lg-2 col-xl-2"></div>
          <div
            className="col-lg-8 col-xl-8"
            style={{
              marginTop: '20px',
            }}
          >
            <Calculator />
          </div>
          <div className="col-lg-2 col-xl-2"></div>
        </section>
      </div>

      <footer>
        <section style={{ paddingBottom: '10px' }}>
          Made with ❤️&nbsp; in Los Angeles
          <br />© 2023 Wilder Innovation Labs. All Rights Reserved.
        </section>
      </footer>
    </div>
  );
}

export default App;
