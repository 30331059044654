import React from 'react';

const NumberInput = (props: any) => (
  <div className="col">
    {/* <label htmlFor={props.name}>{props.label}</label> */}
    <input
      id={props.name}
      className="field form-control"
      type="number"
      {...props}
      value={props.value ?? ''}
    />
  </div>
);

export default NumberInput;
