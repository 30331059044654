import React from 'react';
import { Field } from 'formik';
import StringInput from './common/StringInput';
import NumberInput from './common/NumberInput';

const PlayerRow = ({ index, arrayHelpers }: any) => (
  <div
    className="form-row"
    style={{
      textAlign: 'center',
      marginTop: '5px',
    }}
    key={index}
  >
    <div className="col-3">
      <Field
        as={StringInput}
        name={`players.${index}.name`}
        placeholder="Player"
        required
      />
    </div>
    <Field
      label="Buy In"
      as={NumberInput}
      name={`players.${index}.buyIn`}
      placeholder="Starting"
      min={0}
      required
    />
    <Field
      label="Ending Cash"
      as={NumberInput}
      name={`players.${index}.amount`}
      placeholder="Ending"
      min={0}
      required
    />
    <div className="col">
      <button
        type="button"
        className="btn btn-primary"
        onClick={() => arrayHelpers.remove(index)}
      >
        Delete
      </button>
    </div>
  </div>
);

export default PlayerRow;
